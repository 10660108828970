import { render, staticRenderFns } from "./contact.vue?vue&type=template&id=3b8d7166&scoped=true&"
var script = {}
import style0 from "./contact.vue?vue&type=style&index=0&id=3b8d7166&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b8d7166",
  null
  
)

component.options.__file = "contact.vue"
export default component.exports