<template>
  <div :class="$style.wrap">
    <img src="./title.png" alt="">
    <ul class="clearfix">
      <li v-for="(item,index) in gameTypeList" :key="index">
        <a
          :class="item.id===selectedTypeId?`${$style.selectedItem}`:''"
          @click="handleItemClick(item.id)"
          href="javascript:void(0)"
        >{{item.gameTypeName}}</a>
        <span :class="$style.suffix">&gt;</span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    gameTypeList: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      selectedTypeId: 0
    };
  },
  methods: {
    handleItemClick(id) {
      if(this.selectedTypeId===id){
        return;
      }
      this.selectedTypeId = id;
      EventBus.$emit('game-type-change',this.selectedTypeId);
    }
  }
};
</script>

<style module>
.wrap {
  margin: 38px 0 28px 0;
}

.wrap ul {
  list-style-type: none;
  padding-left: 0;
  margin-top: 14px;
}

.wrap li {
  float: left;
}

.wrap a,
.wrap span {
  font-size: 12px;
  color: #999999;
}

.wrap span {
  margin: 0 6px;
  font-size: 14px;
}

.selectedItem {
  color: #fb710f !important;
}
</style>
