<template>
  <h1>Sorry,page not found!</h1>
</template>

<script>
export default {

}
</script>

<style>

</style>
