import Vue from 'vue'
import App from './App'
import router from './router/index'

import './commons/index';

// 根据域名修改网页标题
// if(process.env.NODE_ENV==='development'){
//   Object.defineProperty(document,'domain',{
//     // value:'mlyol.com',
//     value:'254game.com',
//   })
// }
if(document.domain.endsWith('254game.com')){
  document.title='254game官网';
  var icon=document.querySelector('link[rel="icon"]')
  if(icon){
    icon.href="https://tjy-1253841540.file.myqcloud.com/pic/254game.ico"
  }
}


Vue.config.productionTip = false

new Vue({
  el: '#app',
  router,
  render:h=>h(App)
})
