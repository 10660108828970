<template>
  <div>
    <mly-header></mly-header>
    <slider></slider>
    <boutique-games></boutique-games>
    <mly-footer></mly-footer>
  </div>
</template>

<script>
import slider from "./slider";
import boutiqueGames from "./boutique-games";

export default {
    components: {
        slider,
        boutiqueGames
    },
    created(){

    }
};
</script>

<style>
</style>
