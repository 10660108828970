<template>
  <slider></slider>
</template>
<script>
import slider from "@/views/index/slider";

export default {
  components: {
    slider
  },
  created() {},
  data() {
    return {};
  },
  mounted() {},
  methods: {}
};
</script>
<style>
</style>

