import mlyHeader from './mly-header';
import mlyFooter from './mly-footer';
import mlyQRCode from './mly-qrcode';
import mlyToTop from './mly-to-top'

export default {
  install(Vue) {
    Vue.component(mlyHeader.name, mlyHeader);
    Vue.component(mlyFooter.name, mlyFooter);
    Vue.component(mlyQRCode.name, mlyQRCode);
    Vue.component(mlyToTop.name, mlyToTop);
  }
};
