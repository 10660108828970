<template>
  <keep-alive :include="['product']">
    <router-view/>
  </keep-alive>
</template>
<script>
export default {
  name: "App"
};
</script>
<style src="swiper/dist/css/swiper.css"></style>
<style src="./commons/global.css"></style>
