<template>
<!--
  循环轮播关键：v-if="bannerList.length > 0"
-->
  <swiper :options="swiperOption" id="index_swiper" v-if="bannerList.length > 0">
    <!-- slides -->
    <swiper-slide v-for="(item,index) in bannerList" :key="index">
      <a :style="`background-image:url(${item.imgUrl})`" @click="toGameDetail(item)"></a>
    </swiper-slide>
    <!-- Optional controls -->
    <div class="swiper-pagination" slot="pagination"></div>
  </swiper>
</template>

<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
import axios from "axios";

export default {
  components: {
    swiper,
    swiperSlide
  },
  created() {
    this.getBannerList();
  },
  data() {
    return {
      bannerList: [],
      swiperOption: {
        loop: true,
        autoplay: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true
        }
      }
    };
  },
  methods: {
    toGameDetail(item) {
      try {
        if (item.jumpUrl.trim() == "") {
          return;
        }
        const url = item.jumpUrl.replace("mobile-games/detail", "detail");
        this.$router.push({ path: url });
      } catch (error) {
        console.error(error);
      }
    },
    getBannerList() {
      axios.get("/banner/listBanner?operateSys=1").then(response => {
        if (response.returnCode == this.CONST.QuerySuccess) {
          this.bannerList = response.returnData.list;
        }
      });
    }
  }
};
</script>

<style>
#index_swiper {
  min-width: 1200px;
  height: 532px;
}

#index_swiper a {
  display: block;
  width: 100%;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

#index_swiper .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: #434343;
  border-color: #434343;
  border-radius: 20px;
  margin: 3px;
  opacity: 1;
}

#index_swiper .swiper-pagination-bullet-active {
  width: 14px;
  height: 14px;
  background-color: #fb700f;
  border-color: #fb700f;
}
</style>
