<template>
  <div :class="$style.wrap">
    <div :class="$style.gameList">
      <game-item v-for="(game,index) in gameList" :key="index" :game="game"></game-item>
      <!-- 在显示的游戏的总数为3的倍数的时候就不显示 -->
      <div style="width: 384px;height: auto;visibility:hidden;" v-if="!(gameList.length%3===0)">占位元素</div>
    </div>
    <img
      v-if="showMoreGameBtn"
      src="./more_game.png"
      alt=""
      :class="$style.moreGame"
      @click="handleMoreGameClick"
    >
  </div>
</template>

<script>
import axios from "axios";

import gameItem from "./game-item";

export default {
  props: {
    gameTypeId: {
      type: Number,
      required: true
    }
  },
  components: {
    gameItem
  },
  created() {
    this.queryGameList();
  },
  mounted() {},
  data() {
    return {
      gameList: [],
      pageNum: 1,
      showMoreGameBtn: false
    };
  },
  computed: {},
  methods: {
    handleMoreGameClick() {
      this.queryGameList();
    },
    queryGameList() {
      let url = `/game/listGame?pageNum=${this.pageNum}&pageRow=15${
        this.gameTypeId === 0 ? "" : `&gameTypeId=${this.gameTypeId}`
      }`;

      axios.get(url).then(response => {
        let list = response.returnData.list;
        this.gameList.push(...list);
        this.pageNum += 1;
        this.showMoreGameBtn = response.returnData.totalPage >= this.pageNum;
      });
    }
  }
};
</script>

<style module>
.wrap {
  min-height: 528px;
}
.gameList {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.moreGame {
  display: block;
  margin: 22px auto;
  cursor: pointer;
}
</style>
