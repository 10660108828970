<template>
  <div>
    <mly-header></mly-header>
    <div :class="$style.wrap">
      <game-type :game-type-list="gameTypeList"></game-type>
      <game-list
        v-for="(item,index) in gameTypeList"
        :key="index"
        :game-type-id="item.id"
        v-show="selectedGameTypeId===item.id"
      ></game-list>
    </div>
    <mly-to-top></mly-to-top>
    <mly-footer></mly-footer>
  </div>
</template>
<script>
import axios from "axios";

import gameType from "./game-type";
import gameList from "./game-list";
import gameItem from "./game-item";

export default {
  name:'product',
  components: {
    gameType,
    gameList,
    gameItem
  },
  data() {
    return {
      gameTypeList: [],
      selectedGameTypeId: 0
    };
  },
  computed: {},
  created() {
    this.queryAllGameType();
    EventBus.$on("game-type-change", id => {
      this.selectedGameTypeId = id;
    });
  },
  methods: {
    queryAllGameType() {
      axios.get("/game/aallgametype").then(response => {
        this.gameTypeList.push({
          gameTypeName: "全部",
          id: 0
        });
        this.gameTypeList.push(...response.returnData.list);
      });
    }
  }
};
</script>

<style module>
.wrap {
  width: 1200px;
  margin: 0 auto;
}
</style>
