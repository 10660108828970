<template>
  <img src="@/assets/to_top.png" alt="" :class="$style.toTop" @click="handleClick" v-if="show">
</template>

<script>
export default {
  name: "mlyToTop",
  data() {
    return {
      show: false
    };
  },
  beforeCreate() {
    window.addEventListener("scroll", () => {
      this.show = window.scrollY >= 700;
    });
  },
  beforeDestroy() {
    window.removeEventListener("scroll", () => {});
  },
  methods: {
    handleClick() {
      window.scrollTo(0, 0);
    }
  }
};
</script>

<style module>
.toTop {
  position: fixed;
  right: 100px;
  bottom: 100px;
  z-index: 100;
  border-radius: 50%;
  overflow: hidden;
}
</style>
