import Vue from 'vue';
import axios from 'axios';

/**
 * 注册自定义组件
 */
import MLY from '@/components/index';
Vue.use(MLY);

/**
 * 设置默认属性值
 */
axios.defaults.baseURL = "https://www.254game.com";
// axios.defaults.baseURL = "http://10.0.0.73";
axios.defaults.withCredentials = true;
axios.defaults.transformRequest = [function (data) {
  let ret = '';
  for (let it in data) {
    ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&';
  }
  return ret;
}];
axios.interceptors.response.use(response => {
  let ret = response.data;
  return ret;
});


/**
 * 自定义常量,把常量对象 CONST 挂在到 Vue 实例上
 */
Vue.prototype.CONST={
  QuerySuccess: '100', //查询成功后台返回的状态码
  EditSuccess:'101',    //插入或更新成功后台返回的状态码
  OperateSystem:[ //操作系统
      {value:1,text:"PC"},
      {value:2,text:"iOS"},
      {value:3,text:"Android"}
  ],
  //判断是否为开发环境
  isDev:(()=>{
    return !(process.env.NODE_ENV === "production");
  })()
};
