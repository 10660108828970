<template>
  <router-link :to="`/detail/${game.id}`" :class="$style.wrap" class="a-label-reset">
    <img :src="game.icon" alt="">
    <div :class="$style.desc">
      <p><nobr>{{game.name}}</nobr></p>
      <p><nobr>类型：{{game.gameTypeName}}&nbsp;&nbsp;大小{{game.gameSize}}M</nobr></p>
      <p><nobr>{{game.content}}</nobr></p>
    </div>
  </router-link>
</template>

<script>
export default {
  props: {
    game:{
      type:Object,
      required:true
    }
  },
  data() {
    return {

    };
  }
};
</script>

<style module>
.wrap {
  border: 1px solid #e4e4e4;
  width: 384px;
  height: auto;
  margin-bottom: 19px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.wrap > img {
  width: 121px;
  height: 121px;
  margin:17px;
}
.desc {
  height: 121px;
  margin:17px;
  margin-left: 0;
  flex: 1;
  overflow: hidden;

}

.desc p{
  overflow: hidden;
  text-overflow: ellipsis;
  color: #666666;
  font-size: 13px;
}

.desc p:first-child{
  margin-top: 1em;
  font-size: 16px;
  color: #333333;
}

.btn{
  width: 105px;
  height: 28px;
  background-image: url('./download.png');
  cursor: pointer;
}
</style>
