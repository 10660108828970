<template>
  <section class="container boutique-games">
    <img src="@/assets/boutique_games.png" alt="">
    <div class="row">
      <div v-for="(item,index) in gameList" :key="index" class="col-md-4 col-xs-4 col-sm-4">
        <img :src="item.mediumImg" alt="" class="img-responsive">
        <div class="game-detail">
          <p class="game-name">{{item.name}}</p>
          <p class="slogan">{{item.slogan}}</p>
          <mly-qrcode :game="item" style="margin-top:0;" text-color="white" :text-margin="7"></mly-qrcode>
          <div class="button-group clearfix" style="display:none">
            <a role="button" class="pull-left">官网</a>
            <a role="button" class="pull-right" :href="item.downUrl" target="_blank">下载</a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";

export default {
    created() {
      this.getGameList();
    },
    data() {
        return {
            gameList: []
        };
    },
    methods: {
        getGameList() {
            axios.get("/game/listGame?operateSys=1&pageNum=1&pageRow=9").then(response => {
                if (response.returnCode == this.CONST.QuerySuccess) {
                    this.gameList = response.returnData.list;
                }
            });
        }
    }
};
</script>

<style>
.boutique-games {
    margin-top: 20px;
}

.boutique-games > img {
    margin: 20px 0;
}

.boutique-games .row > div {
    margin-bottom: 20px;
}

.boutique-games .row img {
    width: 100%;
    height: 250px;
}

.game-detail {
    /* display: none; */
    opacity: 0;
    position: absolute;
    top: 0;
    left: 15px;
    right: 15px;
    bottom: 0;
    background-color: rgba(8, 94, 151, 0.8);
    text-align: center;
    vertical-align: middle;
    padding: 0 48px;
    transition: opacity 0.3s ease-in;
}

.game-detail:hover {
    opacity: 1;
}

.game-detail .game-name {
    /* margin-top: 60px; */
    font-size: 30px;
    text-align: center;
    vertical-align: middle;
}

.game-detail .slogan {
    font-size: 16px;
    text-align: center;
    vertical-align: middle;
}

.game-detail .game-name,
.game-detail .slogan,
.game-detail button {
    color: white;
}

.button-group {
    margin-top: 20px;
    padding: 0 32px;
}

.button-group a {
    display: inline-block;
    margin-bottom: 0;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    border-radius: 4px;
    /*  */
    padding: 12px 34px;
    font-size: 12px;
    background-color: transparent;
    border: 1px solid white;
    text-decoration: none;
    color: white;
}

.button-group a:hover {
    background-color: #f86f10;
    border-color: #f86f10;
}
</style>
