<template>
  <div class="qrcode">
    <img :src="qrcode" alt="">
    <p :style="`color:${textColor};margin:${textMargin}px 0`">扫码下载</p>
  </div>
</template>

<script>
import axios from 'axios';
import QRCode from "qrcode";

export default {
    name: "mlyQrcode",
    props: {
        game: {
            type: Object,
            required: true
        },
        textColor:{
          type:String,
          default:'black'
        },
        textMargin:{
          type:Number,
          default:14
        }
    },
    data() {
        return {
            qrcode: null
        };
    },
    mounted(){
      this.generateQRCode();
    },
    methods: {
        generateQRCode() {
            let sourceUrl = `${axios.defaults.baseURL}/game/qcode?id=${
                this.game.id
            }`;
            QRCode.toDataURL(sourceUrl, {
                errorCorrectionLevel: "H",
                margin: 0
            })
                .then(url => {
                    this.qrcode = url;
                })
                .catch(err => {
                    console.error("qrcode generate err");
                });
        }
    }
};
</script>

<style scoped>
.qrcode {
    display: block;
    margin: 46px auto;
    margin-bottom: 0;
}

.qrcode > img {
    width: 114px;
    height: 114px;
    margin: 0 auto;
    display: block;
}

.qrcode > p {
    text-align: center;
    font-size: 14px;
    /* color: black; */
    margin: 14px 0;
}
</style>
